<style scoped lang="scss">
    .userinfo {
        position: relative;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding: 38px 30px;
        background-color: #f6f7fc;
        border-radius: 10px;

        .userinfo-avarat {
            display: flex;
            align-items: center;
            padding-bottom: 40px;
            border-bottom: 1px solid #e8eaf3;

            img {
                width: 78px;
                height: 80px;
                border-radius: 50%;
            }

            .userinfo-info {
                margin-left: 28px;
                display: flex;
                display: flex;
                flex-direction: column;

                button {
                    display: inline-flex;
                    font-size: 14px;
                    letter-spacing: 0px;
                    color: #6c4ecb;
                    margin-left: 13px;
                }

                .username {
                    font-size: 18px;
                    font-weight: 600;
                    font-stretch: normal;
                    letter-spacing: 0px;
                    color: #474747;
                }

                .userinfo-id p {
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    letter-spacing: 0px;
                    color: #484848;
                }
            }
        }
    }

    .userinfo-address {
        margin-top: 50px;
    }

    .userinfo-address-list {
        display: flex;
        margin-bottom: 50px;

        .title {
            width: 86px;
            text-align: right;
            font-size: 14px;
            letter-spacing: 0px;
            color: #666666;
        }

        .value {
            margin-left: 60px;

            font-size: 14px;

            color: #333333;
        }
    }

    // 编辑数据
    .userinfo-edit {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: #e8eaf3;

        .userinfo-edit-title {
            line-height: 30px;
            position: relative;
            text-indent: 32px;

            font-size: 16px;
            font-weight: 600;

            color: #1b1b1b;
        }

        .userinfo-edit-title::after {
            content: "";
            width: 5px;
            height: 18px;
            background-color: #6340c8;
            border-radius: 2.5px;
            position: absolute;
            left: 18px;
            top: 6px;
        }
    }

    .userinfo-edit-content {
        max-width: 1200px;
        padding: 37px 0;
        margin: 0 auto;

        .avatar {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            position: relative;
            overflow: hidden;
            margin: 6px 0;

            img {
                width: 80px;
                height: 80px;
                border-radius: 50%;
            }

            button {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 1000;
                border: none;
                color: #ffffff;
                font-size: 14px;
                width: 80px;
                padding: 6px 13px 8px;
                background: rgba($color: #000000, $alpha: 0.6);
            }
        }
    }

    .form-item-btn {
        margin-top: 60px;
    }

    // 修改密码
    .editpassword {
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: 0.45);
        position: fixed;
        display: none;
        left: 0;
        top: 0;
        z-index: 10000;
    }

    .editpassword-box {
        width: 659px;
        height: 455px;
        background-color: #ffffff;
        box-shadow: 0px 2px 27px 0px rgba(87, 87, 87, 0.15);
        border-radius: 10px;
        position: absolute;
        z-index: 100001;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;

        .editpassword-box-title {
            height: 68px;
            line-height: 68px;
            background-color: #f6f7fc;
            border-radius: 10px 10px 0px 0px;
            font-size: 16px;
            font-weight: 600;
            color: #1b1b1b;
            position: relative;
            text-indent: 32px;
        }

        .editpassword-box-title::after {
            content: "";
            width: 5px;
            height: 16px;
            background-color: #6340c8;
            border-radius: 2.5px;
            position: absolute;
            left: 20px;
            top: 26px;
        }
    }

    .editpassword-content {
        margin-top: 50px;
        padding: 0 80px;
        box-sizing: border-box;

        .editpwdbox-btn {
            margin-top: 50px;
            padding-top: 26px;
            text-align: end;
            border-top: 1px solid #eeeeee;
        }
    }

    ::v-deep {
        .el-input__inner {
            border: none;
            background-color: #f6f7fc;
            padding: 0 27px;
        }

        .userinfo-edit-list .el-input--medium .el-input__inner {
            line-height: 60px;
            height: 60px;
            font-size: 14px;
            color: #666666;
            border-radius: 10px;
        }

        .editpassword-content .el-input--medium .el-input__inner {
            line-height: 47px;
            height: 47px;
            font-size: 14px;
            color: #666666;
            border-radius: 10px;
        }

        .userinfo-edit-list .el-form-item--medium .el-form-item__label {
            line-height: 60px;
            color: #1b1b1b;
            padding: 0 18px 0 0;
        }

        .editpassword-content .el-form-item--medium .el-form-item__label {
            line-height: 47px;
            color: #1b1b1b;
            padding: 0 18px 0 0;
        }

        .avatarclass .el-form-item__label {
            line-height: 80px !important;
            padding: 0 18px 0 0;
        }

        .form-item-btn .el-button--medium {
            height: 40px;
            border-radius: 8px;
            padding: 0 26px;
        }
        .pwdbutton {
            float: right;
        }
    }
</style>
<template>
    <section class="userinfo">
        <div class="userinfo-avarat" style="">
            <el-avatar
                    :size="62"
                    :src="formatImage(userinfo.eduser_image)"
                    fit="cover"
            >
                <img
                        src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
                />
            </el-avatar>
            <div class="userinfo-info">
                <div>
                    <span class="username">{{ userinfo.eduser_name }}</span>
                    <el-button type="text" @click="handelrevise">修改</el-button>
                </div>
                <div class="userinfo-id">
                    <p>
                        <!-- ID：<span>{{ userinfo.ededu_id }}</span> -->
                    </p>
                </div>
            </div>
        </div>

        <div class="userinfo-address">
            <div class="userinfo-address-list">
                <p class="title">地区:</p>
                <div class="value">{{ userinfo.ededu_district }}</div>
            </div>
            <!--  <div class="userinfo-address-list">
              <p class="title">市:</p>
              <div class="value">{{ userinfo.market }}</div>
            </div>
            <div class="userinfo-address-list">
              <p class="title">区:</p>
              <div class="value">{{ userinfo.distinguish }}</div>
            </div> -->
            <div class="userinfo-address-list">
                <p class="title">手机号:</p>
                <div class="value">{{ userinfo.eduser_phone }}</div>
            </div>
            <div class="userinfo-address-list">
                <p class="title">登录账号:</p>
                <div class="value">{{ userinfo.eduser_account }}</div>
            </div>

            <div class="userinfo-address-list pwdbutton">
                <el-button
                        type="primary"
                        class="lightpurple-btn"
                        @click="handeleditPwd()"
                >修改密码
                </el-button
                >
                <el-button class="lightpurple-btn" type="primary" @click="handellogout"
                >退出登录
                </el-button
                >
            </div>
        </div>

        <div class="userinfo-edit" v-if="editShow">
            <div class="userinfo-edit-title">编辑信息</div>
            <div class="userinfo-edit-content">
                <div class="userinfo-edit-list">
                    <el-form
                            label-width="120px"
                            ref="userinfoedit"
                            :model="userinfoedit"
                            :rules="editRule"
                    >
                        <el-form-item label="昵称" prop="eduser_name">
                            <el-input
                                    v-model="userinfoedit.eduser_name"
                                    placeholder="请输入昵称"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="头像" class="avatarclass" prop="eduser_image">
                            <div class="avatar">
                                <el-upload
                                        class="avatar-uploader"
                                        :action="$upload.imgAction"
                                        :headers="$upload.header"
                                        :show-file-list="false"
                                        :on-success="handleAvatarSuccess"
                                        :before-upload="beforeAvatarUpload"
                                >
                                    <el-avatar
                                            fit="cover"
                                            :src="formatImage(userinfoedit.eduser_image)"
                                            class="avatar"
                                    >
                                        <img :src="formatImage(userinfoedit.eduser_image)"/>
                                    </el-avatar>
                                    <p class="camera iconfont">&#xe621;</p>
                                </el-upload>

                                <!-- <el-button @click="handeleditavatar">修改头像</el-button> -->
                            </div>
                        </el-form-item>

                        <el-form-item label="电话号码" prop="eduser_phone">
                            <el-input
                                    v-model="userinfoedit.eduser_phone"
                                    placeholder="请输入电话号码"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="登录账号" prop="eduser_account">
                            <el-input
                                    v-model="userinfoedit.eduser_account"
                                    placeholder="请输入登录账号"
                                    :disabled="true"
                            ></el-input>
                        </el-form-item>

                        <el-form-item style="text-align: right" class="form-item-btn">
                            <el-button type="info" @click="handelclose">取消</el-button>
                            <el-button type="primary" @click="handelsubmit">提交</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>

        <!-- 修改密码 -->
        <div class="editpassword" ref="editpassword">
            <div class="editpassword-box">
                <div class="editpassword-box-title">修改密码</div>

                <div class="editpassword-content">
                    <el-form
                            label-width="120px"
                            ref="pwdFormData"
                            :rules="checkpwdRule"
                            :model="pwdFormData"
                    >
                        <el-form-item label="原密码" prop="eduser_password">
                            <el-input
                                    v-model="pwdFormData.eduser_password"
                                    placeholder="请输入原密码"
                                    type="password"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="新密码" prop="new_password">
                            <el-input
                                    v-model="pwdFormData.new_password"
                                    placeholder="请输入新密码"
                                    type="password"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="确认密码" prop="re_password">
                            <el-input
                                    v-model="pwdFormData.re_password"
                                    placeholder="请输入 确认密码"
                                    type="password"
                            ></el-input>
                        </el-form-item>

                        <el-form-item class="editpwdbox-btn">
                            <el-button type="info" @click="handeleditPwdClose"
                            >取消
                            </el-button
                            >
                            <el-button type="primary" @click="handelPwdEdit">提交</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import {getUserInfo, editUserInfo, updatePassword} from "@api/user";
    import {formatImg} from "@utils";
    import {uploadImg} from "@api/common";
    import {validPassword, validPhone} from "@utils/validate";

    export default {
        name: "",
        data() {
            // 电话号码校验
            let validatePhone = (rule, value, callback) => {
                if (!value) {
                    callback(new Error("请输入手机号"));
                } else if (!validPhone(value)) {
                    callback(new Error("请输入正确的手机号码"));
                } else {
                    callback();
                }
            };
            // 密码校验
            let validatePass = (rule, value, callback) => {
                if (!value) {
                    callback(new Error("请输入密码"));
                } else if (value.length < 8 || value.legnth > 20) {
                    callback(new Error("请输入8~20位密码"));
                } else if (!validPassword(value)) {
                    callback(new Error("密码包含数字、大写字母、小写字母、特殊字符"));
                } else {
                    callback();
                }
            };
            // 再次输入密码校验
            let validatePass2 = (rule, value, callback) => {
                if (!value && this.passwordForm.newPassword) {
                    callback(new Error("请再次输入密码"));
                } else if (value !== this.passwordForm.newPassword) {
                    callback(new Error("两次输入密码不一致!"));
                } else {
                    callback();
                }
            };

            return {
                editShow: false,
                // 个人信息
                userinfo: {
                    ededu_district: "",
                    eduser_phone: "",
                    eduser_account: "",
                },
                editRule: {
                    eduser_name: [
                        {required: true, trigger: "blur", message: "请填写昵称"},
                        {
                            min: 2,
                            max: 20,
                            message: "昵称长度在 2 到 20 个字符",
                            trigger: "blur",
                        },
                    ],
                    eduser_phone: [
                        {required: true, trigger: "blur", message: "请填写电话"},
                        {required: true, validator: validatePhone, trigger: "blur"},
                    ],
                    eduser_account: [
                        {required: true, trigger: "blur", message: "请填写账号"},
                    ],
                    eduser_image: [
                        {required: true, trigger: "blur", message: "请填上传头像"},
                    ],
                },
                checkpwdRule: {
                    eduser_password: [
                        {required: true, trigger: "blur", message: "请填写原密码"},
                        {required: true, validator: validatePass, trigger: "blur"},
                    ],
                    new_password: [
                        {required: true, trigger: "blur", message: "请填写新密码"},
                        {required: true, validator: validatePass, trigger: "blur"},
                    ],
                    re_password: [
                        {required: true, trigger: "blur", message: "请填确认密码"},
                        {required: true, validator: validatePass, trigger: "blur"},
                    ],
                    eduser_image: [
                        {required: true, trigger: "blur", message: "请上传头像"},

                    ],
                },

                // 修改个人信息
                userinfoedit: {
                    ededu_name: "",
                    eduser_image: require("../../assets/images/avarat.png"),
                    ededu_district: "",
                    eduser_phone: "",
                    eduser_account: "",
                },

                // 修改密码
                pwdFormData: {
                    eduser_password: "",
                    new_password: "",
                    re_password: "",
                },
            };
        },
        created() {
            // 获取初始化数据
            this.initData();
        },
        methods: {
            // 点击密码框修改密码
            handeleditPwd() {
                this.$refs.editpassword.style.display = "block";
            },
            //初始化数据
            async initData() {
                let {data: userinfo} = await getUserInfo();
                this.userinfo = userinfo;
                this.userinfoedit = userinfo;
            },
            formatImage(url) {
                return formatImg(url);
            },
            handelrevise() {
                this.editShow = true;
                console.log("....");
            },
            handelclose() {
                this.initData();
                this.editShow = false;
            },
            handeleditavatar() {

            },

            // 上传头像
            // 封面上传
            handleAvatarSuccess(response, file, fileList) {
                // this.loadingstate = false;

                if (response.status == 200) {
                    this.userinfoedit.eduser_image = response.data.address;
                    console.log(this.userinfoedit.eduser_image, "123");
                }
            },

            // 文件格式验证
            beforeAvatarUpload(file) {
                // this.loadingstate = true;
                let index = file.name.lastIndexOf(".");
                let extension = file.name.substr(index + 1);
                let extensionList = [
                    "png",
                    "PNG",
                    "jpg",
                    "JPG",
                    "jpeg",
                    "JPEG",
                    "bmp",
                    "BMP",
                ];
                const isLt2M = file.size / 1024 / 1024 < 10;
                if (!isLt2M) {
                    this.$message({
                        message: "封面不可超出10M",
                        type: "warning",
                        center: true,
                    });

                    return false;
                } else if (extensionList.indexOf(extension) < 0) {
                    this.$message({
                        message: "当前文件格式不支持",
                        type: "error",
                        center: true,
                    });
                    return false;
                }
            },

            uploadImage(param) {
                console.log(1231123111);
                const formData = new FormData();
                formData.append("file", param.file);
                //上传图片;
                uploadImg(formData).then((response) => {
                });
            },
            //提交密码修改
            async handelPwdEdit() {
                this.$refs.pwdFormData.validate(async (valid) => {
                    if (valid) {
                        var res;
                        let submitFormData = JSON.parse(JSON.stringify(this.pwdFormData));

                        res = await updatePassword(submitFormData);

                        if (res.status != 200) {
                            this.$message({
                                message: res.msg,
                                type: "error",
                            });
                            return;
                        }

                        //删除强制修改密码
                        sessionStorage.removeItem("updatePwd");
                        this.$message({
                            message: res.msg,
                            type: "success",
                        });
                        this.initData(); // 请求成功后，调用方法，更新页面数据
                        this.handeleditPwdClose(); // 处理成功，调用方法关闭弹窗
                        sessionStorage.removeItem("updatePwd");
                        sessionStorage.removeItem("Token");
                        sessionStorage.removeItem("Userinfo");
                        this.$router.replace('/login')
                    }
                });
            },
            // 提交修改信息
            async handelsubmit() {
                this.$refs.userinfoedit.validate(async (valid) => {
                    if (valid) {
                        var res;
                        let submitFormData = JSON.parse(JSON.stringify(this.userinfoedit));

                        res = await editUserInfo(submitFormData);

                        if (res.status != 200) {
                            this.$message({
                                message: res.msg,
                                type: "error",
                            });
                            return;
                        }

                        this.$message({
                            message: res.msg,
                            type: "success",
                        });
                        this.initData(); // 请求成功后，调用方法，更新页面数据
                        this.handelclose(); // 处理成功，调用方法关闭弹窗
                        let userinfo = JSON.parse(sessionStorage.getItem("Userinfo"));
                        userinfo.eduser_name = submitFormData.eduser_name;
                        userinfo.eduser_image = submitFormData.eduser_image;
                        sessionStorage.setItem("Userinfo", JSON.stringify(userinfo));          //处理下用户头像问题
                    }
                });
            },
            //退出方法
            async handellogout() {
                sessionStorage.clear();
                localStorage.clear();
                await this.$store.dispatch("user/logout");
                this.$router.push(`/login?redirect=${this.$route.fullPath}`);
            },
            //关闭修改密码
            handeleditPwdClose() {
                this.$refs.editpassword.style.display = "none";
                this.pwdFormData = {
                    eduser_password: "",
                    new_password: "",
                    re_password: "",
                };
            },
        },
    };
</script>
